import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import "./public-path";
import store from "./store";
import {router} from './router';


// 添加全局bus
const Bus = new Vue();
const __qiankun__ = window.__POWERED_BY_QIANKUN__;

Vue.config.productionTip = false;

Vue.directive('resize', {
    bind(el, binding) {
        let width = '', height = '';
        function get() {
            const style = document.defaultView.getComputedStyle(el);
            if (width !== style.width || height !== style.height) {
                binding.value({width, height});
            }
            width = style.width;
            height = style.height;
        }

        el.__vueReize__ = setInterval(get, 200);
    },
    unbind(el) {
        clearInterval(el.__vueReize__);
    },
});

let instance = null;

export async function bootstrap ({data, utils, service, emitFnc, baseUrl}) {
    //挂载主应用下发的store
    Vue.prototype.$mainData = data;
    // 把工具函数挂载在vue $mainUtils对象
    Vue.prototype.$mainUtils = utils;
    Vue.prototype.$mainService = service;
    // 把mainEmit函数一一挂载
    Object.keys(emitFnc).forEach(i => {
        Vue.prototype[i] = emitFnc[i]
    });
    Vue.prototype.$baseUrl = baseUrl;
}


export async function mount ({data = {}, ROUTES, routerBase} = {}) {
    instance = new Vue({
        data: {
            Bus
        },
        router,
        store,
        render: h => h(App, {props: data})
    }).$mount("#app");
}

export async function unmount () {
    instance.$destroy();
    instance = null;
    router = null;
}

// 单独开发环境
__qiankun__ || mount();

