const user = {
    namespaced: true,
    state: {
        // screenDownLoadUrl:'https://fs.iclass30.com/software/common/C30%E6%99%BA%E8%83%BD%E6%95%99%E5%AD%A6(%E5%A4%87%E6%8E%88%E8%AF%BE%E4%BA%8C%E5%90%88%E4%B8%80).exe'
        screenDownLoadUrl:'https://fs.iclass30.com/software/common/C30%E6%99%BA%E8%83%BD%E6%95%99%E5%AD%A6%E4%BA%8C%E5%90%88%E4%B8%80_1.2.523.37722_202012211559.exe'
    },
    mutations: {
    },
    actions: {
    }
};

export default user;
