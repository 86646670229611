import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const router = new Router({
    mode: process.env.NODE_ENV === "development" ? "hash" : "history",
    routes: [
        {
            path: "/",
            redirect: "/index",
        },
        {
            path: "/index",
            component: resolve =>
                require(["../views/index.vue"], resolve)
        },
        {
            path: "/shandonindex",
            component: resolve =>
                require(["../views/screenIndex.vue"], resolve)
        },
        {
            path: "/yqarouse",
            component: resolve =>
                require(["../views/yqArouse.vue"], resolve)
        }
    ]
});



